const products = [
  {
    title: "Hallo, mein Name ist Claudia Koch!",
    label: "1.1 Vorstellung",
    text:
      "Ich unterstützte sie dabei, körperliche Gesundheitsprobleme zu überwinden, emotionale Belastungen und Traumata zu heilen, emotionalen Stress zu reduzieren.",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/claudia-1@2x.jpg",
    duration: 3,
    typeContent: ["video", "document"],
  },
  {
    title: "Schön, dass du da bist!",
    label: "1.2 Einleitungg",
    text:
      "Herzlich willkommen zum meinem ganzheitlichen online DetoxMe! Online-Kurs.",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-detox-word-written-in-notebook-healthcare-concept-digital-detox-as-disconnected-internet-life-1852312339.jpg",
    duration: 9,
    typeContent: ["video"],
  },
  {
    title: "Schön, dass du da bist!",
    label: "Darum brauchst du eine Entgiftungskur!",
    text: "Darum brauchst du eine Entgiftungskur!",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-winter-seasonal-smoothie-drink-detox-female-in-woolen-sweater-holding-bottle-of-green-smoothie-or-1214737027.png",
    duration: 5,
    typeContent: ["video"],
  },
  {
    title: "Lass dich nicht vergiften!",
    label: "2.2 Gifte reduzieren & Ausscheidungsbahnen stärken",
    text: "Darum brauchst du eine Entgiftungskur!",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-dark-jar-with-poisonous-solution-etiquette-with-a-warning-sign-about-the-content-of-poisonous-1146470141.png",
    duration: 6,
    typeContent: ["video"],
  },
  {
    title: "Was du über deine Leber wissen musst!",
    label: "2.3 Aufgaben der Leber",
    text: "Darum brauchst du eine Entgiftungskur!",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-man-holding-liver-illustration-against-gray-wall-background-concept-with-mental-health-protection-1234973029.png",
    duration: 6,
    typeContent: ["video"],
  },
  {
    title: "Erhebe deinen Vitalstatus für ein optimales Ergebnis",
    label: "Fragebogen",
    text:
      "Dieser Vorher-Nachher-Vergleich hilft dir, deine Erfolge messbar zu machen und zeigt dir auf, wo du die größten Veränderungen erfahren wirst.",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-close-up-of-businessman-filling-customer-survey-form-172082504.png",
    typeContent: ["document"],
  },
  {
    title: "Schaffe eine Umgebung, die das Entgiften fördert",
    label: "4.1 Umfeld gestalten",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-peaceful-lifestyle-shot-of-woman-sitting-on-dock-at-sunset-on-lake-bunyonyi-uganda-africa-1132921691.png",
    duration: 6,
    typeContent: ["video"],
  },
  {
    title: "Deine Checkliste für die Detox-Kur",
    label: "4.2 Utensilien besorgen",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-close-up-hand-holding-pen-on-check-list-paper-and-the-format-for-filling-in-information-in-705724939.png",
    duration: 1,
    typeContent: ["video"],
  },
  {
    title: "Deine neuen Routinen für die nächsten Tage",
    label: "4.3 Ablauf eines Detox-Tages",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-daily-routine-written-on-paper-with-wooden-background-desk-clock-dice-compass-and-pen-top-view-535155220.png",
    duration: 5,
    typeContent: ["video"],
  },
  {
    title: "So startest du perfekt in deinen Detox-Tag",
    label: "4.4 MOrgenritual",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-beautiful-brunette-is-waking-up-in-the-morning-stretches-in-the-bed-sun-shines-on-her-from-the-1451195096.png",
    duration: 5,
    typeContent: ["document"],
  },
  {
    title: "Stoffwechsel ankurbeln und die Entgiftung fördern",
    label: "4.5 zwei gläser wasser mit flohsamenschalen",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-young-woman-drinking-water-570636958.png",
    duration: 4,
    typeContent: ["video"],
  },
  {
    title: "Aktiviere deine Leber für ein optimales Ergebnis",
    label: "4.6 leberwickel",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-man-holding-liver-illustration-against-gray-wall-background-concept-with-mental-health-protection-1262047429.png",
    duration: 3,
    typeContent: ["video"],
  },
  {
    title: "Unterstützendes Ritual zur Ausleitung der Gifte",
    label: "4.7 basenbad",
    text:
      "Nutze den positiven Einfluss des Basenbades auf das Säure-Basen-Gleichgewicht deines Körpers.",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-spa-decoration-natural-organic-products-on-a-bathtube-loofah-towel-and-frangipani-flower-384225742.png",
    duration: 4,
    typeContent: ["video"],
  },
  {
    title: "Was essen wir und auf was wird verzichtet?",
    label: "5.1 Entgiftungsplan",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-do-and-dont-s-word-with-notepad-and-green-plant-on-wooden-background-605508449.png",
    duration: 2,
    typeContent: ["video", "document"],
  },
  {
    title: "Tabus und Alternativen",
    label: "5.2 die dont’s",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-a-hand-signs-raise-arm-and-showing-a-palm-with-five-fingers-meaning-dont-or-stop-on-white-1357376045.png",
    duration: 2,
    typeContent: ["video"],
  },
  {
    title: "Das muss auf deinen Speiseplan",
    label: "5.3 die do’s",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-healthy-food-background-healthy-vegan-vegetarian-food-in-paper-bag-vegetables-and-fruits-on-white-1610617150.png",
    duration: 12,
    typeContent: ["video"],
  },
  {
    title: "Optionale Unterstützung deines Körpers",
    label: "5.4 nahrungsergänzung",
    text:
      "Entgiftungsorgane benötigen bestimmte Vitamine und Mineralien für eine optimale Funktionsweise",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-multivitamins-and-supplements-with-fresh-and-healthy-fruits-on-white-wooden-background-1149648821.png",
    typeContent: ["document"],
  },
  {
    title: "So gut kann Wasser schmecken",
    label: "5.5 Getränke",
    text:
      "Für das Ausscheiden von Toxinen ist Trinken von Wasser essentiell. Hier erhältst du Tipps für Wasser mit Geschmack",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-infused-detox-water-with-lemon-and-cucumber-slices-blueberry-and-mint-ice-cold-summer-cocktail-or-1101501737.png",
    duration: 8,
    typeContent: ["video", "document"],
  },
  {
    title: "Das DetoxMe!-Kochbuch",
    label: "5.6 Rezepte",
    text:
      "Hier findest du die komplette Rezeptsammlung für deine individuelle Menügestaltung",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/Mockup_Rezeptbuch.png",
    duration: 8,
    typeContent: ["document"],
  },
  {
    title: "Tagesablauf",
    label: "6.1 Einführung",
    text:
      "Hier findest du die komplette Rezeptsammlung für deine individuelle Menügestaltung",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-wooden-cubes-with-letters-of-the-alphabet-a-piece-of-paper-with-the-text-your-daily-routine-1778303048.png",
    duration: 8,
    typeContent: ["document", "video"],
  },
  {
    title: "Gedanken gewahr werden",
    label: "6.2 emotionales Detox",
    text:
      "Hier findest du die komplette Rezeptsammlung für deine individuelle Menügestaltung",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-sad-woman-sitting-on-sofa-at-home-deep-in-thoughts-thinking-about-important-things-791221375.png",
    duration: 5,
    typeContent: ["video"],
  },
  {
    title: "Dein heutiger Speiseplan",
    label: "6.3 Tagesrezepte",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-various-kitchen-utensils-recipe-cookbook-and-cooking-classes-concept-1147272551.png",
    duration: 5,
    typeContent: ["document"],
  },
  {
    title: "Tagesablauf",
    label: "7.1 Einführung",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-alarm-clock-and-daily-routine-words-blackboard-on-desk-white-background-chalkboard-write-routine-1483486922.png",
    duration: 4,
    typeContent: ["video", "document"],
  },
  {
    title: "Gedanken und Übung zum inneren Kritiker",
    label: "7.2 emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-evil-men-pointing-at-stressed-woman-desperate-young-businesswoman-sitting-at-desk-in-her-office-266038373.png",
    duration: 8,
    typeContent: ["video"],
  },
  {
    title: "Dein heutiger Speiseplan",
    label: "7.3 TAGESREZEPTE",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-various-kitchen-utensils-recipe-cookbook-and-cooking-classes-concept-1147272551.png",
    duration: 4,
    typeContent: ["document"],
  },
  {
    title: "Tagesablauf",
    label: "8.1 Einführung",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-wooden-cubes-with-letters-of-the-alphabet-a-piece-of-paper-with-the-text-your-daily-routine-1778303048.png",
    duration: 5,
    typeContent: ["video", "document"],
  },
  {
    title: "Dankbarkeit und Dankbarkeitstagebuch",
    label: "8.2 emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-gratitude-journal-concept-writing-i-am-grateful-in-journal-1765316210.png",
    duration: 6,
    typeContent: ["video"],
  },
  {
    title: "Dein heutiger Speiseplan",
    label: "8.3 TAGESREZEPTE",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-various-kitchen-utensils-recipe-cookbook-and-cooking-classes-concept-1147272551.png",
    typeContent: ["document"],
  },
  {
    title: "Tagesablauf",
    label: "9.1 einführung",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-alarm-clock-and-daily-routine-words-blackboard-on-desk-white-background-chalkboard-write-routine-1483486922.png",
    duration: 6,
    typeContent: ["document", "video"],
  },
  {
    title: "Emotionalen Stress reduzieren",
    label: "9.2 emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-young-tired-single-mother-suffers-from-headache-closed-eyes-touch-forehead-sitting-on-couch-while-1606546576.png",
    duration: 5,
    typeContent: ["document"],
  },
  {
    title: "Dein heutiger Speiseplan",
    label: "9.3 TAGESREZEPTE",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-various-kitchen-utensils-recipe-cookbook-and-cooking-classes-concept-1147272551.png",
    typeContent: ["document"],
  },
  {
    title: "Tagesablauf",
    label: "10.1 einführung",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-wooden-cubes-with-letters-of-the-alphabet-a-piece-of-paper-with-the-text-your-daily-routine-1778303048.png",
    duration: 5,
    typeContent: ["document", "video"],
  },
  {
    title: "Achtsamkeit: Sehen, Hören, Fühlen",
    label: "10.2 emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-light-head-435449356.png",
    duration: 5,
    typeContent: ["video"],
  },
  {
    title: "Dein heutiger Speiseplan",
    label: "10.3 TAGESREZEPTE",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-various-kitchen-utensils-recipe-cookbook-and-cooking-classes-concept-1147272551.png",
    typeContent: ["document"],
  },
  {
    title: "Silent Walk",
    label: "10.4 Emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-beautiful-young-woman-walks-in-krka-national-park-in-croatia-at-sunset-128818372.png",
    duration: 3,
    typeContent: ["video"],
  },

  {
    title: "Tagesablauf",
    label: "11.1 einführung",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-alarm-clock-and-daily-routine-words-blackboard-on-desk-white-background-chalkboard-write-routine-1483486922.png",
    duration: 6,
    typeContent: ["video", "document"],
  },
  {
    title: "Gefühle beobachten und benennen",
    label: "11.2 emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-new-mindset-new-results-words-letter-motivational-self-development-business-typography-quotes-1413617213.png",
    duration: 3,
    typeContent: ["video"],
  },
  {
    title: "Dein heutiger Speiseplan",
    label: "11.3 TAGESREZEPTE",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-various-kitchen-utensils-recipe-cookbook-and-cooking-classes-concept-1147272551.png",
    duration: 3,
    typeContent: ["document"],
  },
  {
    title: "Atemübung",
    label: "11.4 emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-yoga-breathing-inhale-exhale-sign-at-fitness-class-on-lightbox-inspirational-message-with-exercise-1578434065.png",
    duration: 4,
    typeContent: ["video"],
  },
  {
    title: "Tagesablauf",
    label: "12.1 einführung",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-wooden-cubes-with-letters-of-the-alphabet-a-piece-of-paper-with-the-text-your-daily-routine-1778303048.png",
    duration: 4,
    typeContent: ["video", "document"],
  },
  {
    title: "Selbstliebe",
    label: "12.2 emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-young-beautiful-chinese-woman-wearing-casual-sweater-over-isolated-white-background-hugging-oneself-1772867801.png",
    duration: 9,
    typeContent: ["video"],
  },
  {
    title: "Dein heutiger Speiseplan",
    label: "12.3 TAGESREZEPTE",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-beautiful-young-woman-sitting-on-bed-and-shows-gesture-heart-with-fingers-portrait-of-smiling-lady-768625207.png",
    duration: 3,
    typeContent: ["document"],
  },
  {
    title: "Herzlichen Glückwunsch Du hast es geschafft!",
    label: "wie es jetzt weitergeht!",
    text:
      "Du kannst jetzt deinen Fragenbogen, den du zu Beginn des Kurses ausgefüllt hast, noch einmal zur Hand nehmen.",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-close-up-of-asian-man-hand-holding-looking-watching-using-binoculars-with-copyspace-technology-536781592.png",
    duration: 3,
    typeContent: ["document"],
  },
  {
    title: "Gedanken gewahr werden",
    label: "Emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-sad-woman-sitting-on-sofa-at-home-deep-in-thoughts-thinking-about-important-things-791221375.png",
    duration: 5,
    typeContent: ["video"],
  },
  {
    title: "Gedanken und Übung zum inneren Kritiker",
    label: "Emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-evil-men-pointing-at-stressed-woman-desperate-young-businesswoman-sitting-at-desk-in-her-office-266038373.png",
    duration: 8,
    typeContent: ["video"],
  },
  {
    title: "Dankbarkeit und Dankbarkeitstagebuch",
    label: "Emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-gratitude-journal-concept-writing-i-am-grateful-in-journal-1765316210.png",
    duration: 6,
    typeContent: ["video"],
  },
  {
    title: "Emotionalen Stress reduzieren",
    label: "Emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-young-tired-single-mother-suffers-from-headache-closed-eyes-touch-forehead-sitting-on-couch-while-1606546576.png",
    duration: 5,
    typeContent: ["video"],
  },
  {
    title: "Achtsamkeitsübung: Sehen, Hören, Fühlen",
    label: "Emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-light-head-435449356.png",
    duration: 4,
    typeContent: ["video"],
  },
  {
    title: "Übung Silent Walk",
    label: "Emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-beautiful-young-woman-walks-in-krka-national-park-in-croatia-at-sunset-128818372.png",
    duration: 3,
    typeContent: ["video"],
  },
  {
    title: "Gefühle beobachten und benennen",
    label: "Emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-new-mindset-new-results-words-letter-motivational-self-development-business-typography-quotes-1413617213.png",
    duration: 3,
    typeContent: ["video"],
  },
  {
    title: "Atemübung",
    label: "Emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-yoga-breathing-inhale-exhale-sign-at-fitness-class-on-lightbox-inspirational-message-with-exercise-1578434065.png",
    duration: 4,
    typeContent: ["video"],
  },
  {
    title: "Selbstliebe",
    label: "Emotionales Detox",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-young-beautiful-chinese-woman-wearing-casual-sweater-over-isolated-white-background-hugging-oneself-1772867801.png",
    duration: 9,
    typeContent: ["video"],
  },
  {
    title: "Selbstliebe",
    label: "Glücksliste",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-young-beautiful-chinese-woman-wearing-casual-sweater-over-isolated-white-background-hugging-oneself-1772867801.png",
    duration: 10,
    typeContent: ["video"],
  },
  {
    title: "Klopf die negativen Gefühle weg",
    label: "Glücksliste",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-young-beautiful-chinese-woman-wearing-casual-sweater-over-isolated-white-background-hugging-oneself-1772867801.png",
    duration: 10,
    typeContent: ["video"],
  },
  {
    title: "Erkenne deine Bedürfnisse",
    label: "Glücksliste",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/stock-photo-young-beautiful-chinese-woman-wearing-casual-sweater-over-isolated-white-background-hugging-oneself-1772867801.png",
    duration: 10,
    typeContent: ["video", "document"],
  },
  {
    title: "Der Dankbarkeitsbrief",
    label: "Glücksliste",
    text:
      "Ich will mit dem Mythos aufräumen, dass entgiften heißt zu Fasten und zu verzichten.",
    sourceUrl:
      "https://detoxme.at/wp/wp-content/uploads/2021/03/jade-seok-kFrDzOolOP0-unsplash.png",
    duration: 10,
    typeContent: ["video"],
  },
]
export default products
